
export default {
    name: 'YfnVideo',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        src: String,
        name: {
            type: String,
            default: () => {
                return 'myVideoPlayer'
            }
        },
        hasPlayBtn: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        options: {
            type: Object,
            default: () => {
                return {}
            }
        },
        playsinline: {
            type: Boolean,
            default: () => {
                return true
            }
        }
    },
    data() {
        return {
            clear: '',
            hasVideo: false,
            hasVideoBtn: false,
            hasVideoMask: false,
            hasNoControls: false,
            hasShowPoster: true,
            state: {}, // 视频状态
            defaultOptions: {}, // 默认配置项
            rsOptions: {}, // 组合配置项
        }
    },
    computed: {},
    watch: {
        options: {
            handler() {
                this.setOptionsDebounce()
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 视频监听
        stateChanged(e) {
            this.state = e || {}
            this.$emit('statechanged', e)
        },
        canplay(e) {
            this.hasShowPoster = false
            this.$emit('canplay', e)
        },
        play(e) {
            this.hasShowPoster = false
            this.$emit('play', e)
        },
        // 视频操作
        handleVideo() { // 操作-视频点击
            if(!this[this.name]) return
            if(this.state.timeupdate) {
                this[this.name].pause()
            } else {
                this[this.name].play()
            }
        },
        // 设置配置项
        setOptionsDebounce() {
            this.$nextTick(() => {
                process.client && this.setOptions()
            })
        },
        setOptions() {
            this.hasVideo = !!this.src
            // 控制手柄
            const controlBar = {
                ...this.defaultOptions.controlBar,
                ...this.options.controlBar
            };
            // 播放源
            const sources = [{
                type: 'video/mp4',
                // src: this.src
                src: ''
            }];
            // 封面
            const poster = this.options.poster ? this.options.poster : `${this.src}?x-oss-process=video/snapshot,t_1,f_jpg,w_750,m_fast`;
            this.rsOptions = {
                sources,
                poster,
                ...this.defaultOptions,
                ...this.options,
                controlBar
            }
            // 自定义播放按钮
            this.hasVideoBtn = this.hasVideo && this.hasPlayBtn && !this.rsOptions.controls
            this.hasNoControls = this.hasPlayBtn && !this.rsOptions.controls
            this.hasVideoMask = !this.hasPlayBtn && this.rsOptions.autoplay
            let clear = setTimeout(() => {
                this.rsOptions.sources[0].src = this.src
                clearTimeout(clear)
            }, 100)
        },
        // 初始化
        initialSet() {
            this.defaultOptions = {
                muted: true,                              // 是否静音
                // aspectRatio: '16:9',                   // 视频比例
                playbackRates: [0.5, 0.7, 1.0, 1.5, 2.0], // 播放速度列表
                controls: false,                          // 控制手柄
                preload: 'auto',                          // 视频加载模式
                autoplay: false,                          // 是否等浏览器准备好后自动播放
                loop: false,                              // 结束后是否重新开始
                fluid: true,                              // 是否按比例缩放以适应容器
                notSupportedMessage: 'loading...',
                controlBar: {
                    timeDivider: true,                   // 当前时间和持续时间的分隔符
                    durationDisplay: true,               // 显示持续时间
                    remainingTimeDisplay: true,          // 是否显示剩余时间功能
                    fullscreenToggle: true,              // 全屏按钮
                    playToggle: true,                    // 播放暂停按钮
                    volumeMenuButton: true,              // 音量控制
                    currentTimeDisplay: true,            // 当前播放时间
                    progressControl: true,               // 点播流时，播放进度条，seek控制
                    liveDisplay: true,                   // 直播流时，显示LIVE
                    playbackRateMenuButton: true,        // 播放速率，当前只有html5模式下才支持设置播放速率
                }
            }
        }
    },
}
