
import OSS from 'ali-oss'

export default {
    name: 'YfnUploader',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        accept: {
            type: String,
            default: 'image/*', // video/*,image/*
        },
        afterRead: {
            type: Function
        },
        options: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            ossKey: '',
            file: {},
            default_options: {
                folderName: 'm',
                hasBuffer: true,
                fileName: new Date().getTime()
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 文件上传
        uploadAfter(e) {
            const originFile = {
                name: e.file.name,
                size: e.file.size,
                type: e.file.type,
                lastModified: e.file.lastModified,
                lastModifiedDate: e.file.lastModifiedDate
            }
            this.default_options.fileName = new Date().getTime()
            this.options.folderName ?? (this.options.folderName = this.default_options.folderName)
            this.options.hasBuffer ?? (this.options.hasBuffer = this.default_options.hasBuffer)
            this.options.fileName ?? (this.options.fileName = this.default_options.fileName)
            this.file.origin = e
            // Blob 转 Buffer
            this.blobToBuffer(e.file, this.options).then(res => {
                this.file.buffer = res
                const file = this.file.origin.file;
                const fileTypeIndex = file.name.lastIndexOf('.')
                const fileType = file.name.slice(fileTypeIndex + 1)
                this.options.hasUseFileName && (this.options.fileName = file.name.slice(0, fileTypeIndex))
                const aliOption = {
                    file: this.file.buffer.url,
                    folderName: `yfn-upload/${this.options.folderName}/`,
                    domain: 'https://cdn2.selleroa.com/',
                    fileType,
                    fileName: `${this.options.fileName}.${fileType}`,
                    secure: true
                }
                // 文件上传到 ali-oss
                this.aliOssUpload(aliOption).then(res => {
                    this.file.oss = res
                    this.file.origin = originFile
                    this.afterRead && this.afterRead(this.file)
                })
            })
        },
        // blob 转 buffer
        blobToBuffer(blob, obj) {
            return new Promise((resolve, reject) => {
                const read = new FileReader()
                read.onload = e => {
                    let o = {
                        ...e,
                        url: e.target.result,
                    }
                    resolve(o)
                }
                if(obj.hasBuffer) {
                    read.readAsArrayBuffer(blob)
                } else {
                    read.readAsDataURL(blob)
                }
            })
        },
        // ali-oss 上传
        aliOssUpload(options) {

            return new Promise((resolve, reject) => {
                let Buffer = OSS.Buffer;
                const uploadPath = `${options.folderName}${options.fileName}`;
                const localFile = Buffer.from(options.file, 'base64');

                if(!OSS) return
                if(!options.file) return

                this.getOssKey().then(res => {
                    const client = new OSS({
                        accessKeyId: res.accessKeyId,
                        accessKeySecret: res.accessKeySecret,
                        bucket: res.bucket,
                        region: res.endpoint,
                        secure: !!options.secure
                    })

                    client.put(uploadPath, localFile).then(res => {
                        resolve({
                            reUrl: `${options.domain}${res.name}`,
                            ...res
                        })
                    })
                })
            })
        },
        // 获取 ali-oss 秘钥
        getOssKey() {
            return new Promise((resolve, reject) => {
                if(this.ossKey) {
                    resolve(this.ossKey)
                    return
                }
                this.$api.common.getAliOss().then(response => {
                    const res = response.result;
                    const decode = atob(res);
                    const key = JSON.parse(decode);
                    this.ossKey = key
                    resolve(key)
                })
            })
        }
    },
}
