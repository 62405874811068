
export default {
    name: 'YfnTag',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            defaultOptions: {
                color: '#FFF8EB',
            }
        }
    },
    computed: {
        tagStyle() {
            const color = this.$attrs.color;
            const defaultStyle = {
                '#EC4E25': {
                    'font-family': 'Mulish-Semi',
                    'border-color': `rgba(236, 78, 37, 0.7)`
                }
            };
            return defaultStyle[color]
        }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
