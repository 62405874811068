
export default {
    name: 'YfnTab',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        options: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            defaultOptions: {
                color: '#333',
                ellipsis: false,
                'title-inactive-color': '#333',
                'title-active-color': '#333',
            },
            rsOptions: {}
        }
    },
    computed: {},
    watch: {
        options: {
            handler(n, o) {
                this.setOptions(n)
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setOptions(obj) {
            const n = JSON.parse(JSON.stringify(obj));
            this.rsOptions = {...this.rsOptions, ...n}
            if(this.rsOptions.list && this.rsOptions.key) {
                // 键值对替换
                const key = this.rsOptions?.key || {};
                const keyArr = Object.keys(key);
                this.rsOptions.list.forEach(item => {
                    keyArr.forEach(c => {
                        item[c] = item[key[c]] ?? ''
                    })
                })
            }
        }
    },
}
